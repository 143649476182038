import * as React from 'react'
import { Waypoint } from 'react-waypoint'
import * as style from './NumberedStack.module.scss'

class Entry extends React.Component {
  state = { hidden: true }

  onEnter() {
    this.setState({ hidden: false })
  }

  render() {
    const { image, headline, type, description, index } = this.props

    return (
      <Waypoint onEnter={this.onEnter.bind(this)}>
        <li data-hidden={this.state.hidden} className={style.contentWrapper} style={{ backgroundImage: `url(${image})` }}>
          {headline && (
            <div className={style.headline}>
              <div className={`${style.headlineBolt} medium-2 large-1 large-offset-0 xlarge-1 xlarge-offset-0`} />
              <div className={`${style.headlineContent} small-10 medium-7 large-5 xlarge-3`}>{headline}</div>
            </div>
          )}
          <div className={`${style.content}`}>
            <div className={`${style.entryIndex} medium-2 large-1 large-offset-0 xlarge-1 xlarge-offset-0`}>0{index + 1}</div>
            <div className={`small-10 medium-7 large-5 xlarge-5`}>
              <h3 className={style.type}>{type}</h3>
              <p className={style.description}>{description}</p>
            </div>
          </div>
        </li>
      </Waypoint>
    )
  }
}

export default ({ content, numbered }) => (
  <section className={style.section}>
    <ul data-hidden-parent>
      {content.map((entry, index) => (
        <Entry key={index} numbered={numbered} index={index} {...entry} />
      ))}
    </ul>
  </section>
)
