import * as React from 'react'
import {
  lens,
  svg,
  hiddenEllipsis,
  ellipsis,
  hiddenContentWrapper,
  contentWrapper,
  hiddenTitle,
  title,
  lenses,
  description,
  heading,
  lenseCount,
  content as contentStyle,
  circles,
} from './Lenses.module.scss'
import Esg from './Esg'

const Lens = ({ content, hidden }) => {
  return (
    <li className={lens}>
      <svg className={svg} version="1.1" viewBox="0 0 500 500" preserveAspectRatio="xMinYMin meet" xmlns="http://www.w3.org/2000/svg">
        <ellipse className={hidden ? hiddenEllipsis : ellipsis} ry="245" rx="245" cy="250" cx="250" />
      </svg>
      <div className={hidden ? hiddenContentWrapper : contentWrapper}>
        <span className={hidden ? hiddenTitle : title}>{content}</span>
      </div>
    </li>
  )
}

export const Lenses = ({ hidden }) => {
  return (
    <section className={lenses}>
      <div className={`${description} small-12 medium-10 xlarge-4`}>
        <h2 className={heading}>
          The <span className={lenseCount}>Four</span> Lenses
        </h2>
        <p className={contentStyle}>
          The investment lenses constitute the essential components of our fundamental research process, and together they inform our
          investment decisions. Environmental, Social, and Governmental (“ESG”) factors and attendant ESG risks are also assessed by the firm at the research level.
        </p>
      </div>
      <ul className={`${circles} small-12 large-10 xlarge-8 column`}>
        <Esg hidden={hidden} />
        {['Political', 'Economic', 'Technical', 'Legal'].map((circle, index) => (
          <Lens key={index} content={circle} hidden={hidden} />
        ))}
      </ul>
    </section>
  )
}
