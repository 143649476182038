// extracted by mini-css-extract-plugin
export var circles = "emso-Lenses-module__circles";
export var content = "emso-Lenses-module__content";
export var contentWrapper = "emso-Lenses-module__contentWrapper";
export var description = "emso-Lenses-module__description emso-Layout-module__column";
export var ellipsis = "emso-Lenses-module__ellipsis";
export var heading = "emso-Lenses-module__heading";
export var hiddenContentWrapper = "emso-Lenses-module__hiddenContentWrapper emso-Lenses-module__contentWrapper";
export var hiddenEllipsis = "emso-Lenses-module__hiddenEllipsis emso-Lenses-module__ellipsis";
export var hiddenTitle = "emso-Lenses-module__hiddenTitle emso-Lenses-module__title";
export var lens = "emso-Lenses-module__lens";
export var lenseCount = "emso-Lenses-module__lenseCount";
export var lenses = "emso-Lenses-module__lenses emso-Layout-module__row";
export var svg = "emso-Lenses-module__svg";
export var title = "emso-Lenses-module__title";