import React from 'react'
import styled, { css } from 'styled-components'
import { Grid } from 'antd'
import { isEmpty } from 'lodash'

const { useBreakpoint } = Grid

const GREEN_BACKGROUND_COLOR = '#01A65D'

const SvgWrapper = styled.div`
  height:60px;
  width: 100%;
  position: relative;
`

const Wrapper = styled.div`
  padding: 20px 20px;
  ${props => props.sm && css`
    padding: 30px 50px;
  `}
`

const Svg = styled.svg`
  position: absolute;
  left: 0;
  width: 100%;
  height: 60px;
  overflow: initial!important;
`

const Rect = styled.rect`
  transform: translateY(0) rotate(0deg);
  transition: stroke-dashoffset 3000ms, transform 1000ms, fill 3000ms 1300ms, stroke 2000ms 1500ms;
  fill: #10255c;
  stroke: #e7b50c;
  stroke-dasharray: 2000;
  stroke-dashoffset: 2000;
  stroke-width: 2.5px;
  ${props => props.active && css`
    stroke: ${GREEN_BACKGROUND_COLOR};
    stroke-dashoffset: 0;
    fill: ${GREEN_BACKGROUND_COLOR};
  `}
`
const Text = styled.text`
  font-size: 15px;
  fill: white;
  text-transform: uppercase;
  opacity: 0;
  transition-timing-function: ease;
  transition-duration: 1200ms;
  transition-delay: 2500ms;
  transition-property: opacity;
  ${props => props.active && css`
    opacity: 1;
  `}
`

const Esg = ({ hidden }) => {
  const screen = useBreakpoint()
  if (isEmpty(screen)) {
    return null
  }
  const text = screen.sm ? "Environmental, Social, and Governance Factors" : "ESG"
  return (
    <Wrapper sm={screen.sm}>
      <SvgWrapper>
        <Svg active={!hidden}>
          <Rect active={!hidden} rx="10"  ry="10" width="100%" height="100%" />
          <Text active={!hidden} x="50%" y="50%" dominantBaseline="middle" textAnchor="middle">
            {text}
          </Text>
        </Svg>
      </SvgWrapper>
    </Wrapper>
  )
}

export default Esg