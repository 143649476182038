import macroEconomicsAnalysisImage from './images/macroeconomic-analysis.jpg'
import focusCountriesIdentifiedImage from './images/focus-countries-identified.jpg'
import opportunitiesIdentifiedImage from './images/opportunities-identified.jpg'
import investmentDecisionImage from './images/investment-decision.jpg'

export default [
  {
    image: macroEconomicsAnalysisImage,
    type: 'Macroeconomic analysis',
    description: 'Identifying countries, regions, and investible securities of interest.',
  },
  {
    headline: 'Focus Countries Identified',
    image: focusCountriesIdentifiedImage,
    type: 'DETAILED RESEARCH',
    description: 'Fundamental analytical review, legal research, frequent travel to target countries, and use of local advisors.',
  },
  {
    headline: 'Opportunities Identified',
    image: opportunitiesIdentifiedImage,
    type: 'ANALYSIS OF INVESTMENT OPPORTUNITY',
    description: 'Analyze all opportunities including sizing and hedging of individual positions and portfolio themes.',
  },
  {
    headline: 'Investment Decision',
    image: investmentDecisionImage,
    type: 'DAILY PORTFOLIO REVIEW',
    description: 'Review of market developments and portfolio hedges, involving all relevant portfolio managers and research analysts.',
  },
]
