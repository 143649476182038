import investmentApproachIcon from './images/investment-approach.svg'

export default [
  {
    image: investmentApproachIcon,
    title: 'ABSOLUTE RETURN',
    content: 'Strategic and tactical asset allocation, investing in the broadest range of investment products.',
  },
  {
    image: investmentApproachIcon,
    title: 'UNCONSTRAINED LONG ONLY',
    content: 'Strategic asset allocation in predominately long-only investments, aiming to outperform emerging market bond indices.',
  },
  {
    image: investmentApproachIcon,
    title: 'EM PRIVATE CREDIT',
    content: 'Private credit investments with a multi-year investment horizon.',
  },
]
