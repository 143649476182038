import fixedIncomeIcon from './images/fixed-income.svg'
import localMarketsIcon from './images/local-markets.svg'
import corporateCreditIcon from './images/corporate-credit.svg'
import privateCreditIcon from './images/investment-approach.svg'

export const heroData = {
  title: 'Approach',
  header:
    "Emso's investment approach is sophisticated and granular. The team focuses on identifying idiosyncratic opportunities across emerging markets.",
  content: [
    'Our process analyzes the relevant political, legal, technical, and economic risk factors of each investment opportunity. We use comprehensive risk analysis on our portfolios with the aim of minimizing drawdowns.',
    "Our approach seeks to provide positive and uncorrelated long-term returns through active management in a historically volatile asset class. Emso's portfolios benefit from over 20 years of development and integration of trading, settlement, technology, and risk management within one firm.",
  ],
}

export const boxes = [
  {
    image: fixedIncomeIcon,
    title: 'Sovereign and Quasi-Sovereign Fixed Income',
    content:
      'Investing in sovereign and quasi-sovereign fixed income opportunities, including those that have a complex political or credit story.',
  },
  {
    image: localMarketsIcon,
    title: 'Local Markets',
    content:
      'Investing in interest rates and currencies across emerging markets, using local currency government bonds, bills, or derivatives.',
  },
  {
    image: corporateCreditIcon,
    title: 'Corporate Credit',
    content: 'Investing in non-government related corporate credit opportunities within emerging markets.',
  },
  {
    image: privateCreditIcon,
    title: 'Private Credit',
    content: 'Investing in scalable asset backed lending platforms targeting SMEs and consumers in Latin America, CEEMEA, and India.',
  },
]
