import {Waypoint} from 'react-waypoint'
import * as React from 'react'
import { Helmet } from 'react-helmet'
import Hero from '../components/Hero/Hero'
import Layout from '../layouts/Layout'
import { heroData, boxes } from '../data/approach'
import Heading from '../components/Heading/Heading'
import Boxes from '../components/Boxes/Boxes'
import analysis from '../data/analysis'
import investmentApproach from '../data/investmentApproach'
import { Lenses } from '../components/Lenses/Lenses'
import NextSection from '../components/NextSection/NextSection'
import NumberedStack from '../components/NumberedStack/NumberedStack'
import heroImage from './images/brazil-background.jpg'
import * as style from './approach.module.scss'

export default class Approach extends React.Component {
  state = {
    investmentApproachHidden: true,
    investmentCompetenciesHidden: true,
    fourLensesHidden: true,
  }

  investmentCompetenciesSectionEntered() {
    this.setState({ investmentCompetenciesHidden: false })
  }

  investmentApproachSectionEntered() {
    this.setState({ investmentApproachHidden: false })
  }

  fourLensesSectionEntered() {
    this.setState({ fourLensesHidden: false })
  }

  render() {
    return (
      <Layout blueFooter={false} className={style.approachPage} hasDownArrow>
        <Helmet>
          <title>Approach - Emso</title>
          <meta property="og:title" content="Approach" />
          <meta property="og:type" content="website" />
          <meta property="og:image" content={`https://www.emso.com${heroImage}`} />
          <meta
            name="description"
            content="Emso's investment approach is sophisticated and granular. The team focuses on identifying idiosyncratic opportunities across emerging markets."
          />
        </Helmet>
        <Hero image={heroImage} {...heroData} />
        <NextSection />
        <Waypoint onEnter={this.investmentCompetenciesSectionEntered.bind(this)}>
          <div data-hidden={this.state.investmentCompetenciesHidden} className={style.investmentCompetencies}>
            <Heading
              title="Core Investment Competencies"
              content={['Experienced, integrated team ', <br key={1} />, 'focused on four areas']}
            />
            <Boxes content={boxes} />
          </div>
        </Waypoint>
        <NextSection />
        <Waypoint onEnter={this.fourLensesSectionEntered.bind(this)}>
          <div data-hidden={this.state.fourLensesHidden}>
            <Lenses hidden={this.state.fourLensesHidden} />
          </div>
        </Waypoint>
        <NextSection offset={-90} />
        <Heading title="Portfolio Construction Process" content={['Fundamental analysis ', <br key={1} />, 'with daily review']} />
        <NumberedStack content={analysis} />
        <NextSection />
        <Waypoint onEnter={this.investmentApproachSectionEntered.bind(this)}>
          <div data-hidden={this.state.investmentApproachHidden} className={style.investmentApproach}>
            <Heading
              blue={true}
              title="Three Investment Strategies"
              content={['Emerging markets fixed income', <br key={1} />, 'investing across three core strategies']}
            />
            <Boxes content={investmentApproach} isLight={true} />
           <p className={style.strategies}>Opportunistic and tailored strategies are also offered to meet specific needs of institutional investors.</p>
          </div>
        </Waypoint>
      </Layout>
    )
  }
}
